body {
  font-family: Arial, sans-serif;
  background-color: #f8f9fa;
}
@media (min-width: 768px) { 
  .header2{
      height: 50rem;
  } 
}

.header2 { 
  min-height: 24rem;
  /* background-image: url(./components/home/imagen/img_principal.jpg);      */
  background-size: cover;
  background-repeat: no-repeat; 
  background-position: center center;  
 
}
.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 250px;
  padding: 20px;
  background-color: #0d6efd;
  color: white;
}

.principal{
  position: relative;
}  


.container .text-center input[type="radio"] {
  display: none;
}

.content {
  margin-left: 250px;
  padding: 20px;  
}

@media (max-width: 768px) {
  .sidebar {
    width: 100%;
    height: 30%;    
  }

  .content {
    margin-left: 0;  
    margin-top: 120px;  
  }  
}

.navegacion {
  display: block;
}
.nav-link{
  color: white;
}

.right {
  padding-left: 64%;  
}

@media (min-width: 768px){
  .right{
    padding-left: 85%;
  }
}

.logo {
  max-width: 60px;
  max-height: 60px; 
}

.parrafo {
  padding-top: 2rem;
}
.top {
  padding-top: 2rem;
}

.acciones {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
}

.texto {
  opacity: 0;
}